/*
--primary-color: #274654;
--dark-primary-color: #1F3742;
--light-primary-color: #407188;
--accent-color: #84b761;
--netural-background-color: #f7f7f7;
--primary-text: #212121;
--secondary-text: #757575;
--light-text: #ffffff;
*/

:root {
    --main-bg-color: brown;
    
    --primary-hue: 190;
    
    --primary-color: hsl(var(--primary-hue),91%,16%);
    --dark-primary-color: hsl(var(--primary-hue),95%,10%);
    --light-primary-color: hsl(var(--primary-hue),95%,26%);
    --very-light-primary-color: hsl(var(--primary-hue),95%,26%);
    --accent-color: hsl(150, 85%, 40%);
    
    --btn-primary-color: var(--accent-color);
    
    --netural-background-color: #f7f7f7;
    --primary-text: #212121;
    --secondary-text: #757575;
    --light-text: #ffffff;
    
    --warning-color: orange;
    --info-color: var(--light-primary-color);
    --danger-color: red;
}