
body.has-sidebar {

    html {
    overflow:hidden;
    }

    #sidebar li {
        margin: 0px 0px;
    }
    #sidebar li.seperator {
        border-bottom: none;
    }
    .navbarTopLogo {
        display: inline-block;
        height: 200px;
        width: 250px;
        position: fixed;
        top: 100px;
        color: white;
        font-weight: bold;
        text-align: center;
        font-size: 41px;
    }

    .wrapper {
    
        background: #f5f5f5;
        height: ~'calc( 100vh - 90px )';
    }

    #sidebar {
        min-width: 250px;
        max-width: 250px;
    }

    #sidebar.active {
        margin-left: -300px;
    }
    #xapiEndpoint
    {
        padding-top: 16px;
        display: inline-block;
    }
    #sidebar {
        min-width: 301px;
        max-width: 301px;
    
        height: ~'calc( 100vh - 91px )';
        overflow-y: auto;
    }

    a[data-toggle="collapse"] {
        position: relative;
    }

    #sidebar a[aria-expanded="false"]::before,
    #sidebar a[aria-expanded="true"]::before {
        content: '\e259';
        display: block;
        position: absolute;
        right: 0px;
        top: ~'calc( 50% - 5px )';
        font-family: 'Glyphicons Halflings';
        font-size: 10px;
        color: var(--accent-color);
        
    }

    #sidebar a[aria-expanded="true"]::before {
        content: '\e260';
    }

    #sidebar .disabledLite
    {
        color:gray !important;
        opacity:.5;
        pointer-events: none;
    }

    @media (max-width: 991px) {
        #content {
            top:1px;
            height: ~'calc(100vh - 50px)';
        }
        .wrapper{
            height: ~'calc( 100vh - 51px )';
        }
        #sidebar {
          
            height: ~'calc( 100vh - 50px )';
           
        }
    }

    @media (max-width: 767px) {
        .stuck_container {
            display: none;
        }
        header {
            padding-bottom: 0px;
        }
        #sidebar {}
        #sidebar.active {}
    }



    li a,
    li a:hover,
    li a:focus {
            color: inherit;
            text-decoration: none;
            transition: all 0.3s;
        }

    #sidebar {
        /* don't forget to add all the previously mentioned styles here too */
        background: var(--primary-color);
        color: #fff;
        transition: all 0.3s;
    
        box-shadow: 3px 1px 10px #5656561f;
        z-index: 100;
        position: fixed;
        /* font-size: 10px; */
    }

    #sidebar .sidebar-header {
        padding: 20px;
        background: var(--light-primary-color);
        color: white;
    }

    #sidebar ul.components {
        /* padding: 20px 0; */
        /* border-bottom: 1px solid #47748b; */
    }

    #sidebar ul p {
        color: #fff;
        padding: 10px;
    }

    a[aria-expanded="true"] {
        color: #fff;
        background: var(--light-primary-color);
        border-left: 7px solid var(--light-primary-color);
    }

    #sidebar ul li a[aria-expanded="true"] {
        border-left: 7px solid var(--light-primary-color);
    }

    #sidebar ul li a {
        padding: 6px 10px;
        font-size: 18px;
        display: block;
        overflow: hidden;
        cursor: pointer;
        border-left: 8px solid #04404d;
        /* text-indent: 10px; */
        padding-left: 52px;
        text-indent: -29px;
    }


    #sidebar ul li a:hover .fa {
        color: var(--dark-primary-color);
    }

    #sidebar ul li a:hover {
        color: #ffffff;
        background: var(--accent-color);
        border-color: var(--accent-color);
    }

    #sidebar h3 {
        color: white;
    }

    #sidebar ul li.active>a {
        color: #fff;
        background: var(--dark-primary-color);
        border-left: 7px solid var(--accent-color);
    }

    #sidebar ul ul {

        box-shadow: inset -1px -3px 6px 0px #0000001f;

        background: var(--primary-color);
    }

    #sidebar ul ul a {
        font-size: 16px !important;
        padding-left: 30px;
        background: transparent;
    }

    main { 
        width: 100%;
        margin-left: 0px;
    
    }

    main.sidebarOpen {
        width: ~'calc( 100% - 300px)';
        margin-left: 300px;
    }

    main.sidebarOpen #sidebarCollapse .glyphicon{
        transform: rotate(180deg);
    }

    #sidebarCollapse {
        transition: transform .5s;
        float: left;
        color: #ffffff;
        background: transparent;
        padding: 6px;
        background: var(--dark-primary-color);
        margin: 0px 0px;
        height: 39px;
        z-index: 10000;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        /* box-shadow: 0px 0px 5px rgba(0,0,0,.5); */
        line-height: 20px;
        position:fixed;
        border-width: 0;
        margin-top: 0px;
        z-index: 10000001;
    }

    #sidebarCollapse:focus {
        border: none;
        outline: none;
        box-shadow: none;
    }

    #sidebar .fa {
        color: var(--accent-color);
        margin-right: 7px;
        width: 22px;
        text-align: center;
    }

    #sidebar ul li ul li a {
        padding-left: 70px;
        text-indent: 0;
    }

    #sidebar ul li a[aria-expanded="true"]:hover {
        color: #000;
        background: var(--light-primary-color);
    }
    @media (min-width: 991px) {
        #content {
            top: 0px;
            height: ~'calc(100vh - 90px)';
        }
    }
    #content {
        transition: width, margin-left .3s;
        display: flex;
        flex-direction: column;
    
        overflow:auto;
        
    }

    @media (max-width: 767px)
    {
        
    #content {
        height: ~'calc(100vh - 50px)';
    }
    .wrapper
    {
        
        height: ~'calc(100vh - 51px)';
        

    }
        main {
        
            
        }
        main.sidebarOpen {
        
            
        }
        #sidebar
        {
        
        }

        main #sidebar {
        
            margin-top: 0px;
        }
        main main.sidebarOpen {
        
            margin-top: 0px;
        }
        main main{
        
            margin-top: 0px;
        }
        .lrsMenuButton {
            display:none;
        }

    }
    @media (max-width: 678px) {
        #sidebar {
        
            z-index: 100;
            position: fixed;
            display: block;
            /* font-size: 10px; */
        
            width: 100%;
            max-width: unset;
            min-width: unset;
        
            min-height: unset;
            max-height: 0px;

            box-shadow: 0px 15px 20px 0px #00000052;
            transform: unset;
            margin-left: 0;
            height: auto;
            overflow: hidden;
            transition: max-height 1s;
        }
        #sidebar.active
        {
            
            z-index: 10000001;
                height: auto;
                max-height: ~"calc(100vh - 50px)";
                min-height: unset;
                overflow: hidden;
                margin-left: unset;
                overflow: auto;
        }

       
        
        #content
        {
            
            display: inline-block;
            position: relative;
            transition: none;
        }
        main #content
        {
            margin-top: 0px;
        }
        #sidebarCollapse.active {
           padding-right:28px
        }
        main {
            width: 100%;
            margin-left: 0px;
        
        }
        main.sidebarOpen {
            width: 100%;
            margin-left: 0px;
        
        }
        .lrsMenuButton {
            display:block;
        }
    }

    .lrscontent
    {
        flex: 1 0 auto
    }
    footer
    {
            flex-shrink: 0;
    }

}
@media (max-width: 678px) {
    .primary-menu-open #sidebar.active,.primary-menu-open #sidebarCollapse
    {
        
       display: none;
    }

}
