checkbox label,
label.checkbox-inline {
  cursor: pointer;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .checkbox label,
.form-group.is-focused label.checkbox-inline {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .checkbox label:focus,
.form-group.is-focused .checkbox label:hover,
.form-group.is-focused label.checkbox-inline:focus,
.form-group.is-focused label.checkbox-inline:hover {
  color: rgba(0, 0, 0, 0.54);
}
fieldset[disabled] .form-group.is-focused .checkbox label,
fieldset[disabled] .form-group.is-focused label.checkbox-inline {
  color: rgba(0, 0, 0, 0.26);
}
.checkbox input[type=checkbox],
label.checkbox-inline input[type=checkbox] {
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  pointer-events: none;
}
.checkbox .checkbox-material,
label.checkbox-inline .checkbox-material {
  vertical-align: middle;
  position: relative;
  top: 3px;
}
.checkbox .checkbox-material:before,
label.checkbox-inline .checkbox-material:before {
  display: block;
  position: absolute;
  top: -5px;
  left: 0;
  /* content:""; */
  background-color: rgba(0, 0, 0, 0.84);
  height: 20px;
  width: 20px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  -webkit-transform: scale3d(2.3, 2.3, 1);
  transform: scale3d(2.3, 2.3, 1);
}
.checkbox .checkbox-material .check,
label.checkbox-inline .checkbox-material .check {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 2px;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0px 0px 1px 1px #003466 inset;
  background: white;
}
.checkbox .checkbox-material .check:before,
label.checkbox-inline .checkbox-material .check:before {
  position: absolute;
  content: "";
  -webkit-transform: rotate(45deg) translateX(-7px) translateY(6px);
  -ms-transform: rotate(45deg) translateX(-7px) translateY(6px);
  -o-transform: rotate(45deg) translateX(-7px) translateY(6px);
  transform: rotate(45deg) translateX(-7px) translateY(6px);
  display: block;
  margin-top: -4px;
  margin-left: 6px;
  width: 0;
  height: 0;
  -webkit-box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
  box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
}
.checkbox input[type=checkbox]:focus + .checkbox-material .check:after,
label.checkbox-inline input[type=checkbox]:focus + .checkbox-material .check:after {
  opacity: 0.2;
}
.checkbox input[type=checkbox]:focus:checked + .checkbox-material:before,
label.checkbox-inline input[type=checkbox]:focus:checked + .checkbox-material:before {
  -webkit-animation: rippleOn .5s;
  -o-animation: rippleOn .5s;
  animation: rippleOn 0.5s;
}
.checkbox input[type=checkbox]:focus:checked + .checkbox-material .check:before,
label.checkbox-inline input[type=checkbox]:focus:checked + .checkbox-material .check:before {
  -webkit-animation: checkbox-on 0.3s forwards;
  -o-animation: checkbox-on 0.3s forwards;
  animation: checkbox-on 0.3s forwards;
}
.checkbox input[type=checkbox]:focus:checked + .checkbox-material .check:after,
label.checkbox-inline input[type=checkbox]:focus:checked + .checkbox-material .check:after {
  -webkit-animation: rippleOn .5s forwards;
  -o-animation: rippleOn .5s forwards;
  animation: rippleOn 0.5s forwards;
}
.checkbox input[type=checkbox]:focus:not(:checked) + .checkbox-material:before,
label.checkbox-inline input[type=checkbox]:focus:not(:checked) + .checkbox-material:before {
  -webkit-animation: rippleOff .5s;
  -o-animation: rippleOff .5s;
  animation: rippleOff 0.5s;
}
.checkbox input[type=checkbox]:focus:not(:checked) + .checkbox-material .check:before,
label.checkbox-inline input[type=checkbox]:focus:not(:checked) + .checkbox-material .check:before {
  -webkit-animation: checkbox-off 0.3s forwards;
  -o-animation: checkbox-off 0.3s forwards;
  animation: checkbox-off 0.3s forwards;
}
.checkbox input[type=checkbox]:focus:not(:checked) + .checkbox-material .check:after,
label.checkbox-inline input[type=checkbox]:focus:not(:checked) + .checkbox-material .check:after {
  -webkit-animation: rippleOff .5s forwards;
  -o-animation: rippleOff .5s forwards;
  animation: rippleOff 0.5s forwards;
}
.checkbox input[type=checkbox]:checked + .checkbox-material .check,
label.checkbox-inline input[type=checkbox]:checked + .checkbox-material .check {
  color: #003466;
  border-color: #003466;
}
.checkbox input[type=checkbox]:checked + .checkbox-material .check:before,
label.checkbox-inline input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #003466;
  -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
}
.checkbox input[type=checkbox][disabled] + .circle,
.checkbox input[type=checkbox][disabled]:not(:checked) ~ .checkbox-material .check,
.checkbox input[type=checkbox][disabled]:not(:checked) ~ .checkbox-material .check:before,
fieldset[disabled] .checkbox,
fieldset[disabled] .checkbox input[type=checkbox],
fieldset[disabled] label.checkbox-inline,
fieldset[disabled] label.checkbox-inline input[type=checkbox],
label.checkbox-inline input[type=checkbox][disabled] + .circle,
label.checkbox-inline input[type=checkbox][disabled]:not(:checked) ~ .checkbox-material .check,
label.checkbox-inline input[type=checkbox][disabled]:not(:checked) ~ .checkbox-material .check:before {
  opacity: 0.5;
}
.checkbox input[type=checkbox][disabled] + .checkbox-material .check:after,
label.checkbox-inline input[type=checkbox][disabled] + .checkbox-material .check:after {
  background-color: rgba(0, 0, 0, 0.87);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
@-webkit-keyframes checkbox-on {
  0% {
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@-o-keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes checkbox-on {
  0% {
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@-webkit-keyframes checkbox-off {
  0% {
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  25% {
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0;
    height: 0;
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    -webkit-box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 10px inset;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 10px inset;
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    -webkit-box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
  }
}
@-o-keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0;
    height: 0;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    -o-transform: rotate(0);
    transform: rotate(0);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 10px inset;
  }
  100% {
    -o-transform: rotate(0);
    transform: rotate(0);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
  }
}
@keyframes checkbox-off {
  0% {
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  25% {
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0;
    height: 0;
    -webkit-box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    -webkit-box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 10px inset;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 10px inset;
  }
  100% {
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    -webkit-box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
  }
}
@-webkit-keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
.togglebutton {
  vertical-align: middle;
}
.togglebutton,
.togglebutton .toggle,
.togglebutton input,
.togglebutton label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.togglebutton label {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .togglebutton label:focus,
.form-group.is-focused .togglebutton label:hover {
  color: rgba(0, 0, 0, 0.54);
}
fieldset[disabled] .form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}
.togglebutton label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}
.togglebutton label .toggle {
  text-align: left;
}
.togglebutton label .toggle,
.togglebutton label input[type=checkbox][disabled] + .toggle {
  content: "";
  display: inline-block;
  width: 30px;
  height: 15px;
  background-color: rgba(80, 80, 80, 0.7);
  border-radius: 15px;
  margin-right: 15px;
  -webkit-transition: background .3s ease;
  -o-transition: background .3s ease;
  transition: background .3s ease;
  vertical-align: middle;
}
.togglebutton label .toggle:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #F1F1F1;
  border-radius: 20px;
  position: relative;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  left: -5px;
  top: -2px;
  -webkit-transition: left 0.3s ease, background 0.3s ease, -webkit-box-shadow 0.1s ease;
  -o-transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}
.togglebutton label input[type=checkbox][disabled] + .toggle:after,
.togglebutton label input[type=checkbox][disabled]:checked + .toggle:after {
  background-color: #BDBDBD;
}
.togglebutton label input[type=checkbox] + .toggle:active:after,
.togglebutton label input[type=checkbox][disabled] + .toggle:active:after {
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}
.togglebutton label input[type=checkbox]:checked + .toggle:after {
  left: 15px;
}
.togglebutton label input[type=checkbox]:checked + .toggle {
  background-color: rgba(85, 119, 238, 0.5);
}
.togglebutton label input[type=checkbox]:checked + .toggle:after {
  background-color: #003466;
}
.togglebutton label input[type=checkbox]:checked + .toggle:active:after {
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(85, 119, 238, 0.1);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(85, 119, 238, 0.1);
}