
@font-face{
    font-family:'FontAwesome';
    src:url('../cdn/fontawesome-webfont.eot?v=4.3.0');
    src:url('../cdn/fontawesome-webfont.eot?#iefix&v=4.3.0') format('embedded-opentype'),url('../cdn/fontawesome-webfont.woff2?v=4.3.0') format('woff2'),url('./fontawesome-webfont.woff?v=4.3.0') format('woff'),url('../cdn/fontawesome-webfont.ttf?v=4.3.0') format('truetype'),url('../cdn/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular') format('svg');
    font-weight:normal;
    font-style:normal
}

@import (inline) './css/customCheckbox.css';
@import (inline) './cdn/lato raleway crete.css';
@import (inline) './frontpage/css/bootstrap.css';
@import (inline) './frontpage/style.css';
@import (inline) './frontpage/css/dark.css';
@import (inline) './frontpage/css/font-icons.css';
@import (inline) './frontpage/css/animate.css';
@import (inline) './frontpage/css/magnific-popup.css';
@import (inline) './frontpage/css/responsive.css';

@import (inline) './frontpage/css/settings.css';
@import (inline) './frontpage/css/layers.css';
@import (inline) './frontpage/css/navigation.css';
@import (inline) './cdn/Titillium.css';
@import (inline) './cdn/font-awesome.min.css';
@import (inline) './frontpage/css/custom.css';
@import (inline) './css/home.less';
@import (inline) './branding/branding.css';
@import (inline) './css/alert.custom.css';

@import (inline) './css/glyphicons.css';
@import (inline) './css/material-design.css';
@import  './css/sidebar.less';
@import (inline) './css/panels.css';

@import (inline) './css/lrspages.css';
@import (inline) './css/variables.css';


