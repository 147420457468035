.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}
.pagination > li:first-child > a, .pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination > li > a, .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #407188;
  text-decoration: none;
  background-color: #fff;
}
.pagination > li {
  display: inline;
}

.nav.nav-tabs li{
  padding: 0em 1em;
  font-size: 1.4em;
}


@media (max-width: 991px)
{
#primary-menu ul li {
    padding-left:1em;
}
}

.form-control, .form-group .form-control {
  padding: 10px;
}

[class*='fa-']:before {
  font-weight: 400;
  font-family: "FontAwesome";
}

.fa {
  line-height: inherit;
}

.form-group {
   margin-bottom: 0rem; 
}

.nav li.separator
{
  border-top:1px solid #DDD;
}