
  .banner-notice {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    pointer-events: none;
  }

.activityCard
{
    margin-top: 1em;
}
li.activityTools {
    margin-left: 50px;
}

.contentTreeNode.notHit
{
    opacity: .3;
}

.filterIcon.fa{
    padding: 2px;
    opacity: .5;
    cursor:pointer   
}
.filterIcon.fa:hover{
   text-decoration: underline;
    
}
.filterIcon.fa.active{
    color:blue;
    opacity: 1;
}
.contentTreePreview
{
    display:flex;
    justify-content: center;
    margin:3em;


}
li.contentTreeNode a span:hover {
    text-decoration: underline;
    cursor: pointer;
}
.activityTools a:hover {
    text-decoration: underline !important;
    cursor: pointer;
}
ul.contentTreeNode
{
    background:none; 
    margin-left:1.5em;
    box-shadow: none;
}
li.contentTreeNode
{
    background:none;
    list-style-type: none;
    font-weight: normal;
}
li.contentTreeNode.bold
{
    font-weight: bold;
}

li.contentTreeNode span.fa {
    margin-right: 1em;
    width: 12px;
}

li.divider {
    margin: 0;
    border-bottom: 1px solid lightgray;
}

li {
    margin: 5px 5px;
}

p, pre, ul, ol, dl, dd, blockquote, address, table, fieldset, form {
    margin-bottom: 5px;
  }
  
.dropdown-menu {
    padding: 0;
}

.well {
    margin-top: 50px;
}
label {
    letter-spacing: unset;
}
.lrscontent .bg4 .fancy-title h3
{
    background: #f7f7f7;    
}
.collapse.in {
    display: block;
}

h3 {
    color: #066b80;
    font-size: 18px;
    line-height: normal;
    font-weight: 600;
    
}

.bg4 {
    background: #f7f7f7;
}

.txt_block {
    padding: 20px;
    background: #fff;
    -webkit-box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 3px 3px 17px rgba(0, 0, 0, 0.1);
}

button{
    border:none;
    background: none;
}

.txt_block {
    padding: 20px;
    background: #fff;
    -webkit-box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 3px 3px 17px rgba(0, 0, 0, 0.1);
}
.price .fa {
    font-size: 95px;
}
.fa {
    line-height: inherit;
  }
[class*='fa-']:before {
    font-weight: 400;
    font-family: "FontAwesome";
  }
h1, h2, h3, h4, h5, h6 {
   
margin: auto;
}
.well4.bg5.lrsName
{
    padding: 0;
}
.banner .price p {
    font-size: 24px;
    line-height: 45px;
    margin-top: 68px;
}

.well {
    min-height: 20px;
    padding: 19px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}
.well4.bg5 h1 {
    /* color: var(--light-primary-color); */
    font-size: 3em;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    padding: 10px 50px;
    white-space: nowrap;
    margin: 0;
    background: #f7f7f7;
    line-height: 100%;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.norowpadding .row, .norowpadding [class*="col-"]
{
   margin-top: 0px;
}
.well4.bg5 a:hover {
    text-decoration: none;
}


 
.bg0 {
    background: white;
}

.txt_block.danger {
    background: rgb(255, 118, 118);
}

.txt_block.danger h3 {
    color: white;
}

a .txt_block.action {
    background: var(--accent-color);
     text-decoration:none;
}


.txt_block.action h3 {
    color: white;
}
.graph {
  width: 100%;
     height: 10vw;
    max-height: 400px;
}
.well4 {
    padding-top: 3vw;
    padding-bottom: ~"calc( 3vw + 15px )";
    height: auto;
}
.well4 h1{
  width: calc( 100% - 231px);
    display: inline-block;
}
.collapsehandle {
    position: relative;
    /* width: 50px; */
    height: 30px;
    display: block;
    text-align: right;
    background: white;
    margin: auto;
    top: 0px;
    line-height: 21px;
    vertical-align: middle;
    border-radius: 100px;
    border: 1px solid lightgray;
    display: flex;
    width: 46px;
    justify-content: space-evenly;
    clear: both;
  
    
}

.collapsehandle:hover {
color:white;
background-color: var(--accent-color);
cursor: pointer;
}

.collapse.in + .collapsehandle
{
  
transform: rotate(180deg);
}

.collapsehandle span {
    vertical-align: middle;
    line-height: 28px;
    top: 0px;
}

.disabled 
{
  opacity: .3;

  pointer-events: none;
}
.topz
{
    z-index: 1000000;
}

#content .disabledLite
{
  opacity: .3;
  pointer-events: none !important;  
}


#content .disabledLite li h3::after
{
    content: "Enterprise Only";
    /* position: absolute; */
    /* left: 9px; */
    background: #0fbd66;
    white-space: nowrap;
    font-size: 12px;
    color: white;
    line-height: 17px;
    padding: 0px 5px;
    /* transform: rotate(-45deg); */
    /* margin-top: -125px; */
    border-radius: 2px;
    display: inline;
    margin-left: 1em;
    }
    .list1 li h3 + p {
        margin-top: 3px;
        min-height: 68px;
        line-height: normal;
        font-size: 18px;
        color: #999;
    }
    .list1 li {
        position: relative;
        padding-left: 130px;
        margin-top: 20px;
       
        text-decoration: none;
        display: inline-block;
    }
    .list1 li:before {
        position: absolute;
        display: inline-block;
        width: 80px;
        height: 80px;
        font-size: 66px;
        line-height: 80px;
        text-align: center;
        color: #ffffff;
        text-shadow: 1px 1px 1px #000;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background: #098199;
        background: -moz-linear-gradient(-45deg, #098199 0%, #098199 49%, #076b7f 50%, #054b59 100%);
        background: -webkit-linear-gradient(-45deg, #098199 0%, #098199 49%, #076b7f 50%, #054b59 100%);
        background: linear-gradient(135deg, #098199 0%, #098199 49%, #076b7f 50%, #054b59 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#098199', endColorstr='#054b59', GradientType=1);
        left: 15px;
        top: -7px;
        -moz-transition: 0.4s;
        -o-transition: 0.4s;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    .list1 li:before {
        position: absolute;
        display: inline-block;
        width: 100px;
        height: 100px;
        font-size: 66px;
        
        text-align: center;
        color: #076b7f;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background: none;
        background: -moz-linear-gradient(-45deg, #098199 0%, #098199 49%, #076b7f 50%, #054b59 100%);
        /* background: -webkit-linear-gradient(
    -45deg
    , #098199 0%, #098199 49%, #076b7f 50%, #054b59 100%); */
        /* background: linear-gradient(
    135deg
    , #098199 0%, #098199 49%, #076b7f 50%, #054b59 100%); */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#098199', endColorstr='#054b59', GradientType=1);
        left: 15px;
        top: -7px;
        -moz-transition: 0.4s;
        -o-transition: 0.4s;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    
    }

    .pull-right > .dropdown-menu {
        right: 0;
        left: auto;
    }
    .open > .dropdown-menu {
        display: block;
    }
    .dropdown-menu {
        box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.2);
    }
    .dropdown-menu {
        border: 0;
        -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    }
    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 160px;
        max-width: 200px;
        padding: 5px 0;
        margin: 2px 0 0;
        font-size: 14px;
        text-align: left;
        list-style: none;
        background-color: #fff;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, .15);
        border-radius: 4px;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    }
    .dropdown-menu li {
        overflow: hidden;
        position: relative;
    }
    .dropdown-menu > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        line-height: 1.42857143;
        color: #333;
        white-space: nowrap;
    }
    .dropdown-menu .divider {
        background-color: rgba(0, 0, 0, 0.12);
    }
    
    .dropdown-menu .divider {
        height: 1px;
        margin: 9px 0;
        overflow: hidden;
        background-color: #e5e5e5;
    }
    .dropdown-menu li a:hover {
        background-color: #0000000f;
        color: #003466;
    }
    
    .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
        color: #262626;
        text-decoration: none;
        background-color: #f5f5f5;
    }
    .matchColPad
    {
        font-size: 1.4em;
    }
    .txt_block p{
        font-size: 16px;
    }
    .cpy-btn
    {
        color: lightgray;
        cursor:pointer;
    }
    .cpy-btn:hover
    {
        color: var(--light-primary-color);
        cursor:pointer;
    }

    .dl-horizontal dt {
        float: left;
        width: 160px;
        overflow: hidden;
        clear: left;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .dl-horizontal dd {
        margin-left: 180px;
    }
    dd {
        margin-bottom: 0;
    }
    .modal.fade.in
    {
        opacity: 1;
    } 
    .modal-backdrop.fade.in
    {
        opacity: .3;
    }
    .modal-body {
        
        max-height: 50vh;
        overflow: auto;
    }
    .modal-content {
        top: 25vh;
    }
    .modal-header .close {
        padding: 1rem;
        margin: -1rem -1rem -1rem 0;
    }



@media (max-width: 991px) {
    .list1 li:before {
        width: 75px;
        height: 75px;
        font-size: 50px;
        line-height:  75px;
        left: 0px;
        top: 5px;
    }
    .list1 li {
        padding-left: 100px;
    }
}
@media (max-width: 767px) {
    .list1 li:before {
        width: 50px;
        height: 50px;
        font-size: 36px;
        line-height: 51px;
        left: 0px;
        top: 5px;
    }
    .list1 li {
        padding-left: 72px;
    }

    .ReactTable .rt-table{


    }
    .ReactTable .rt-table .rt-thead.-header{
        

    }
    .ReactTable .rt-table .rt-thead.-filters{
        

    }
    .ReactTable .rt-tr-group{

    }
    .ReactTable .rt-td{

    }
}

#page-title.page-title-parallax
{
    background-image: url(../branding/banner-bg.jpg);
    padding:0 !important;
}

#statsbanner {
    background-image: url(./banner-bg.jpg);
    background-attachment: fixed;
    background-position: 0 0;
    background-size: cover;
    padding-bottom: 20px;
    padding-top: 20px;
}

.top-margin-50
{
    margin-top: 50px;
}

.ReactTable {
    background: white;
    box-shadow: 3px 3px 17px rgb(0 0 0 / 10%);
}


.list1.row {
    width:100%
}