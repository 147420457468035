.alertify-notifier .ajs-message.ajs-visible {
    -webkit-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-transition-timing-function: cubic-bezier(.175,.885,.32,1.275);
    transition-timing-function: cubic-bezier(.175,.885,.32,1.275);
    opacity: 1;
    max-height: 100%;
    padding: 15px;
    margin-top: 10px;
    background: var(--dark-primary-color);
    color: white;
}