:root {
    --main-bg-color: brown;
    
    --primary-hue: 190;
    
    --primary-color: hsl(var(--primary-hue),91%,16%);
    --dark-primary-color: hsl(var(--primary-hue),95%,10%);
    --light-primary-color: hsl(var(--primary-hue),95%,26%);
    --very-light-primary-color: hsl(var(--primary-hue),95%,26%);
    --accent-color: hsl(150, 85%, 40%);
    
    --btn-primary-color: var(--accent-color);
    
    --netural-background-color: #f7f7f7;
    --primary-text: #212121;
    --secondary-text: #757575;
    --light-text: #ffffff;
    
    --warning-color: orange;
    --info-color: var(--light-primary-color);
    --danger-color: red;
}

:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #680705;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

