.nav.nav-tabs li {
    padding: 0;
    margin: 0;
    font-size: 1.4em;
}
.lrs-usage-graph
{
  
    height: 100px;
    border-bottom: 2px solid #DDD;
    
   
}

.visible-hidden {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
.lrs-usage-graph a {
    display: none !important;
}

#content p {
    word-break: normal;
}

.graph {
    height: 100px;
    border-bottom: 2px solid #DDD;
}

.graph a {
    display: none !important;
}

.alert button.close
{
    line-height: 15px !important;
}

.progressNote
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.box_cnt__no-flow {
    text-align: center;
    color: #022a39;
}
.box_cnt__no-flow.stat
{
    color: #CCC;
}
.box_aside {
    display: none;
}

.box em {
    /* font-size: 26px; */
    font-weight: bold;
    font: inherit;
    font-size: 26px !important;

}
ol
{
    margin-left: 1em;
}
.collapse.in{
    display: block;
  }
.lrs .progress
{
    height:10px;
}
.lrs .progress:last-child
{
    margin-bottom:5px;
}
.revo-slider-emphasis-text {
    font-size: 64px;
    font-weight: 700;
    letter-spacing: -1px;
    font-family: 'Raleway', sans-serif;
    padding: 15px 20px;
    border-top: 2px solid #FFF;
    border-bottom: 2px solid #FFF;
}

.revo-slider-desc-text {
    font-size: 20px;
    font-family: 'Lato', sans-serif;
    width: 650px;
    text-align: center;
    line-height: 1.5;
}

.revo-slider-caps-text {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 3px;
    font-family: 'Raleway', sans-serif;
}

.tp-video-play-button {
    display: none !important;
}

.tp-caption {
    white-space: nowrap;
}

.mailform label input,
.mailform label textarea {
    border: 3px solid #dddddd !important
}

pre {
    background: #EEE;
}
.fluid-width-video-wrapper {
    width: 100%;
    position: relative;
    padding: 0;
}

.fluid-width-video-wrapper iframe,
.fluid-width-video-wrapper object,
.fluid-width-video-wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.page {
    overflow: hidden;
    min-height: 500px;
}
.lead {
    margin-bottom: 5em;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, .1), 0px 0px 15px rgba(0, 0, 0, .1);
    margin-top: 1em;
}
.container.flex
{
    display:flex;
    flex-wrap: wrap;
    justify-content: start;
}

.container.flex .fancy-title.title-dotted-border
{
    flex-basis: 100%;
}
.container.flex 
.postcontent, .sidebar, .col_full, .col_half, .col_one_third, .col_two_third, .col_three_fourth, .col_one_fourth, .col_one_fifth, .col_two_fifth, .col_three_fifth, .col_four_fifth, .col_one_sixth, .col_five_sixth {
    display: block;
    position: relative;
    padding-right: 30px;
    margin-bottom: 50px;   
    margin-right:0;
}
.container.flex .col_one_thrid {
    width: 33%;
}
.container.flex .col_one_fourth {
    width: 25%;
}
.container.flex .col_one_half {
    width: 50%;
}
@media(max-width:991px)
{

    .container.flex .col_one_third {
        width: 50%;
    }
    .container.flex .col_one_fourth {
        width: 50%;
    }
    .container.flex .col_one_half {
        width: 100%;
    }

}

@media(max-width:768px)
{

    .container.flex .col_one_third {
        width: 100%;
    }
    .container.flex .col_one_fourth {
        width: 100%; 
    }
    .container.flex .col_one_half {
        width: 100%;
    }

}

.fancy-title h1, .fancy-title h2, .fancy-title h3, .fancy-title h4, .fancy-title h5, .fancy-title h6 {
    position: relative;
    display: inline-block;
    background-color: #f7f7f7;
    padding-right: 15px;
    color: #444444;
    font-weight: 600;
    line-height: 1.5;
    margin: 0 0 30px 0;
    margin-bottom: 0;
    font-size: 24px;
}

.home .fancy-title h1, .fancy-title h2, .fancy-title h3, .fancy-title h4, .fancy-title h5, .fancy-title h6 {
    position: relative;

    background-color: white;

}

.fancy-title.title-bottom-border h1, .fancy-title.title-bottom-border h2, .fancy-title.title-bottom-border h3, .fancy-title.title-bottom-border h4, .fancy-title.title-bottom-border h5, .fancy-title.title-bottom-border h6 {

    font-size: 20px;
    font-weight: 700;
}


.padding-top-20
{
    padding-top: 20px;
}

.home p.matchColPad
{
    font-size: 15px;
    margin-bottom: 20px;
}

#statsbanner
{
    background-image: url(./banner-bg.jpg);
    background-attachment: fixed;background-position: 0 0;background-size: cover;
    padding-bottom: 46px;
}

.page-title-parallax {
    padding:50px 0px 2px 0px;background-attachment: fixed;background-image: url('./banner-bg.jpg'); background-size: cover; background-position:0px 0px; 
}

#header {
    background: #f7f7f7;
}

.well.bg4
{
    border:none;
    box-shadow: none;
}